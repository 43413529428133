.App {
  margin-left: 50px;
  margin-right: 50px;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  margin-left: 20px;
}

.flex_container {
  display: flex;
  overflow-x: scroll;
  justify-content: left;
}

.image_preview {
  height: 250px;
  width: auto;
  border-radius: 15px;
}

.h3_preview {
  margin: 0px;
  margin-top: 2px;
}

.project {
  border: 3px solid black;
  margin-left: 20px;
  border-radius: 15px;
  padding: 5px;
  width: 447px;
}

.project:hover {
  border: 5px solid black;
}

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}
.collapsible .header {
  background-color: #dddddd;;
  padding: 6px;
  cursor: pointer;
}